@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Patua+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
    url("./fonts/SF-Pro-Display-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: local("SF Pro Display"),
    url("./fonts/SF-Pro-Display-Light.ttf") format("truetype");
  font-weight: 300;
}

body{
  background-color: #fafafa;
  font-family: "SF Pro Display";
}

tbody{
  text-align: center;
}

table{
  width: 100% !important;
}

.form-control{
  border: solid 1px;
  border-radius: 5px;
  padding: 0.1rem;
}

.container-alt{
  padding-top: 8rem !important;
}

.container {
  @apply m-auto max-w-screen-xl p-4
}

@layer utilities {
  .max-w-screen-3xl {
    max-width: 1450px;
  }
}

.search-container {
  @apply m-auto max-w-screen-3xl p-4
}

.card-height{
  height: 650px;
}

.special-edition-text{
  font-family: 'Great Vibes', cursive !important;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.search-margin{
  max-width: 40rem;
  margin: auto;
}

.avatar-border-alt {
  border-radius: 50%;
  height: 6rem;
}

.user-avatar-icon{
  display: flex;
  width: 3rem;
  height: 3rem;
  border: solid 2px black;
  border-radius: 50%;
}

.user-icon{
  display: flex;
  background: gray;
  width: 3rem;
  height: 3rem;
  border: solid 2px black;
  border-radius: 50%;
}


.menu-bar-alt{
  margin-top: auto !important;
  width: 21rem !important;
  height: auto !important;
}

.menu-bar {
  background: white;
  width: 22rem;
  padding: 1rem;
  line-height: 2rem;
  box-shadow: 1px 2px 7px 0px #afafd46b;
  border-radius: 5px;
  margin-top: -1rem
}

.learn-display {
  display: inline-flex;
}

.mobile-menu-drop-icon {
  width: 50px;
  height: 35px;  
  margin-top: 6px;
  margin-left: 5px;
}

.mobile-menu-bar {
  margin-left: -4.2rem;
  width: max-content;
  line-height: 2rem;
  margin-top: 2rem
}

.mobile-dropdown-text {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
}

.verify-img-text{
  color: #C88000;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.verify-card{
  width: 100%;
  display: inline-flex;
  padding: 18px 34px 18px 33px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px dashed #FDBA40;
  background: #FFF;
}

.mobile-nav-bg{
  height: 100vh;
  background: #337c70;
  color: #f4de7f;
}

.mobile-nav-text{
  font-size: 30px;
}

hr.default-mobile {
  border-top: 1px solid #0A4F42;
  width: 20rem;
  margin-top: 2px;
  margin-bottom: 2px;
}

.normal-text{
  color: #000F1F;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 300;
  line-height: 150%; /* 24px */
  letter-spacing: 0.2px;
}

.profile-icon{
  width: 5rem;
  border-radius: 50%;
  height: 5rem;
}

.nav-position{
  position: fixed;
  width: 100%;
  z-index: 6;
}

.th-bg{
  background-color: #C2D5D480;
}

.avatar-border {
  border-radius: 50%;
  height: 10rem;
}

.accepted-text{
  color: #000F1F;
  font-weight: 400;
  font-size: 10px;
}

.accepted-text-alt{
  color: #fff;
  font-weight: 400;
  font-size: 10px;
}

.payment-card{
  width: 80%;
  background-color: rgb(219, 219, 219);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0px 0px 5px 0px #000000;
}

.accepted-badge {
  background: #669A91;
  display: flex;
  padding: 0.2rem;
  height: fit-content;
  width: 5rem;
  align-items: center;
  justify-content: center;
  border: solid 1px;
  border-radius: 5px;
}

.category-text-header{
  color: #000F1F;
  font-family: "SF Pro Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.bold-text-alt-color{
  color: #0A4F42 !important;
}

.bold-text-alt{
  color: #0A4F42 !important;
  font-size: 18px !important;
}

.bold-text-alt-2{
  color: #0A4F42 !important;
  font-size: 22px !important;
}

.bold-text-2{
  color: #000F1F;
  font-family: "SF Pro Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; 
}

.bold-text{
  color: #000F1F;
  font-family: "SF Pro Display";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 150%; */
}

.hustle-card-alt-p{
  padding: 32px !important;
}

.hustle-card-alt{
  height: 20rem !important;
}

.hustle-card-history{
  width: 20rem;
  height: 20rem;
  margin-right: 1rem;
  display: inline-block;
}

.avatar-container{
  display: flex;
  width: 30rem;
}

.avatar-add-button {
  border: solid 1px #0A4F42;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.avatar-container-img {
  border-radius: 5px;
}

.avatar-position{
  cursor: pointer;
  position: absolute;
  bottom: 0.2rem;
  right: 0.2rem;
  width: 4rem;
  height: 4rem;
}

.hustler-card{
  width: 22rem;
}

.share-copy-bg {
  padding: 1rem;
  margin-top: 2rem;
  width: 100%;
  background-color: #F3E4C8;
}

.share-text-copy{
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: bolder;
  line-height: 24px;
  text-align: left;
  padding: 0.2rem;
  border-radius: 5px;
}

.share-text{
  font-family: "SF Pro Display";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.desc-scroll{
  height: 10rem;
  overflow-y: scroll;
}

.booking-skill-container {
  display: flex;
  flex-wrap: nowrap; 
  overflow-x: auto;
  gap: 1rem;
}

.ellipsis-div {
  height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cat-flex-d{
  width: 16rem;
  overflow-x: scroll;
}

.location-ellipsis{
  width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-text{
  color: #000F1F;
  font-family: "SF Pro Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.2px;
  overflow: hidden;
  height: 6rem;
  text-wrap: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.hustle-card{
  padding: 10px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #d7d7d7;
}

.card-image-border{
  border-radius: 5px;
}

.hustle-card-border{
  border: solid 1px #0A4F42;
  /* height: 10rem; */
  border-radius: 5px;
  /* background: #084f41; */
  box-shadow: 0px 0px 5px 0px #9f9696;
}

.card-exp-heading{
  color: #000F1F !important;
}

.card-heading{
  width: 220px;
  color: #0A4F42;
  font-family: "SF Pro Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.2px;
}

.verification-bubble-success{
  background: #00a283 !important
}

.project-thumbnail{
  width: 20rem;
  height: 15rem;
  object-fit: cover;
}

.verification-bubble-default-complete{
  background: #0A4F42 !important;
}

.verification-bubble-default-complete-text {
  color: #fff;
}

.verification-bubble-default{
  width: fit-content;
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 3.25px;
  background: #cd00004d;
}

hr.default-color {
  border-top: 1px solid #c2e0da !important;
}

hr.default {
  margin-top: 1rem;
  border-top: 1px solid #0A4F42;
  width: 100%;
}

.client-text-heading{
  color: #000F1F;
  font-family: "SF Pro Display";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.action-text{
  left: 446px;
  top: 1584px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #696969;
}

.action-img{
  width: 1rem;
  height: 1rem;
  margin: 0.7rem;
}

.action-bubble{
  border-top: 4px solid #ad0707;
  padding: 0.5rem;
  background: #CD000033;
  border-radius: 16px;
}

.hustle-applicant-img{
  border-radius: 35%;
}

.hustle-button-padding{
  padding: 5px 14px 5px 15px !important;
}

.hustle-button-text-progress {
  color: #fff !important;
  font-weight: 100 !important;
  font-size: 14px !important;
}

.hustle-button-text-applied {
  color: #000 !important;
  font-weight: 100 !important;
  font-size: 14px !important;
}

.hustle-button-text-booking {
  color: #fff !important;
  font-weight: 100 !important;
  font-size: 14px !important;
}

.support-text{
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #696969;
}

.support-div{
  height: fit-content;
  padding: 0.5rem;
  border: solid 1px #3C4855;
  border-radius: 5px;
}

.hustle-button-text-reject{
  color: #CD0000 !important
}

.view-project-button-text{
  color:#FFF;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; 
  letter-spacing: 0.2px;
}

.hustle-button-text{
  color:#FFF;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; 
  letter-spacing: 0.2px;
}

.copy-right-circle-text{
  color: black;
  font-size: 12px;
}

.copy-right-circle{
  margin: auto;
  padding: 0.3rem;
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 50%;
  background: white;
  border: solid 1px black
}

.option-card{
  border: solid 1px #0A4F42;
  padding: 0.5rem 5rem 0.5rem 1rem;
  border-radius: 15px;
  box-shadow: 1px 2px 7px 0px #424244;
}

.create-account-link{
  text-decoration: underline;
  color: #003FA5
}

.auth-header{
  color:#000F1F;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.auth-button-text{
  color: #FFF;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.2px;
}

.auth-button-text-alt{
  color: #0A4F42 !important;
}

.auth-button-alt{
  background: transparent !important;
  border: solid 2px #0A4F42 !important;
}

.auth-button-alt-w{
  width: 50% !important;
}

.auth-button {
  display: flex;
  width: 100%;
  height: 56px;
  padding: 16px 14px 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: #0A4F42;
}

.email-color{
  color: #0A4F42;
}

.auth-sub-alt-n{
  width: 25rem !important;
  font-size: 20px !important;
  font-weight: 300 !important;
}

.auth-sub-alt{
  width: auto !important;
}

.auth-mode-card-text {
  color: #535B65;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.2px;
}

.dropdown-margin{
  margin-left: -10rem;
}

.auth-mode-card {
  display: flex;
  width: 164px;
  padding: 1rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 15px;
  border-radius: 16px;
  border: 1px solid #0A4F42;
}

.auth-sub{
  margin-bottom: 2rem;
  width: 18rem;
  color: #000F1F;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0.2px;
}

.auth-input-box-alt {
  padding: 5px 20px !important;
}

.auth-input-box{
  display: flex;
  padding: 13px 10px;
  align-items: center;
  font-size: 18px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #575757;
  width: 100%;
  color: #4f4e4e;
}

.auth-input-box:focus{
  outline: none;
}

.subscription-card-green{
  background-color: rgba(10, 79, 66, 1) !important;
}

.subscription-card{
  max-width: 600px;
  padding: 25px 50px 40px;
  border-radius: 12px;
  background-color: #f2f2f296;
}

.subscription-card-header-alt{
  font-size: 22px !important;
}

.bullet-description {
  font-size: 14px;
  line-height: 24px;
}

.bullet-description-black-color {
  color: rgba(87, 87, 87, 1);
}

.bullet-description-white-color {
  color: #fff;
}

.black-subscription-button {
  width: 100%;
  height: 56px;
  padding: 16px 14px 16px 14px;
  gap: 8px;
  border-radius: 30px;
  border: solid rgba(10, 79, 66, 1) 1px;
  color: rgba(0, 15, 31, 1);
}

.white-subscription-button {
  width: 100%;
  height: 56px;
  padding: 16px 14px 16px 14px;
  gap: 8px;
  border-radius: 30px;
  border: solid 1px #fff;
  background-color: #fff;
  color: rgba(0, 15, 31, 1);
}

.subscription-card-header-green{
  color: #fff !important;
}

.subscription-card-header{
  color: rgba(0, 15, 31, 1);
  font-size: 32px;
  font-family: "SF Pro Display";
  font-weight: 700;
  font-style: normal;
}

.textarea-box{
  border: 1px solid #575757;
  border-radius: 5px;
  padding: 1rem;
}

.dataTables_paginate{
  width: 100% !important
}

.virtual-card-text-alt{
  color: #0A4F42 !important;
}

.selectedNetwork{
  border: 2px solid #0A4F42;
  padding: 0.1rem;
  border-radius: 5px;
}

.virtual-card-text{
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-family: "SF Pro Display";
  color: #fff;
  line-height: 20px;
}

.virtual-card-bg-alt{
  background: #fff;
  padding: 1rem 2rem;
  border: dashed 2px #0A4F42;
  border-radius: 5px;
} 

.virtual-card-bg-mtn{
  background: linear-gradient(to right, #d3d6d680, #ffcb03) !important;
}

.virtual-card-bg-airtel{
  background: linear-gradient(to right, #d3d6d680, #ff0101d4) !important;
}

.virtual-card-bg-vodafone{
  background: linear-gradient(to right, #d3d6d680, #ff0101d4) !important;
}

.virtual-card-bg{
  width: 25%;
  background: linear-gradient(to right, #d3d6d680, #3c4140);
  padding: 1rem 2rem;
  border: solid 1px #0A4F42;
  border-radius: 5px;
}

.wallet-bg{
  background: #C2D5D480;
  padding: 1rem 2rem;
  border: solid 1px #0A4F42;
  border-radius: 5px;
}

.textarea-box:focus{
  outline: none;
}

.transfer-modal-input-box{
  width: 100% !important;
  padding: 10px 30px !important;
}

.edit-modal-input-box{
  width: 100% !important;
  padding: 15px 30px !important;
}

.proposal-input-box-alt{
  width: 100% !important;
}

.proposal-input-box-p-alt{
  padding: 13px 15px !important;
}

.proposal-input-box{
  display: flex;
  padding: 13px 40px;
  align-items: center;
  font-size: 18px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #575757;
  width: 50%;
}

.proposal-input-box:focus{
  outline: none;
}

.proposal-label{
  color: #3C4855;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
  letter-spacing: 0.2px;
}

.active-menu{
  border-bottom: solid 2px #FDBA40;
}

.div-scroll-down {
  height: 80vh;
  overflow-y: auto;
  /* white-space: nowrap;  */
}
img, video {
  max-width: none;
  height: auto;
}

.img-fiiting {
  object-fit: cover;
  width: 100%;
  border-radius: 15px 0 0 15px;
}

.h-full-cs{
  height: 215px !important;
  max-height: 215px !important;
}

.item-card-no-p{
  width: 450px !important;
  height: 215px !important;
  padding: 0px !important;
}

.div-scroll-right {
  padding: 1rem;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap; 
}

.div-scroll-right::-webkit-scrollbar {
  -webkit-appearance: none;
}

.div-scroll-right::-webkit-scrollbar:horizontal {
  height: 3px;
  margin-top: 1rem;
}

.div-scroll-right::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.tooltip-text-color{
  text-align: center;
  color: #fff !important;
}

.tooltip-element-bg {
  background: #084f41;
}

.item-card-p{
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.2px;
  color: #535B65;
}

.portfolio-card-img{
  border-radius: 12px;
  /* background: linear-gradient(to top, rgba(255, 255, 255, 0.579), rgba(255, 255, 255, 1)); */
}

.portfolio-card-bottom{
  background: linear-gradient(to bottom, rgb(255 255 255 / 46%), rgba(255, 255, 255, 1));
  backdrop-filter: blur(20px);
  z-index: 1;
  position: absolute;
  top: 69%;
  border-radius: 0 0 15px 15px;
  padding: 1rem;
}

.portfolio-card{
  display: inline-block;
  background: #fff;
  width: 350px;
  height: 380px;
  border-radius: 15px;
  border: solid 2px #0A4F42;
  margin-right: 1rem;
}

.b-radius{
  border-radius: 15px !important;
}

.item-card-alt{
  padding: 0px !important;
  display: flex !important;
  flex-direction: row !important;
}

.item-card {
  display: inline-block;
  background: #fff;
  padding: 1rem;
  width: 350px;
  height: auto;
  border-radius: 5px;
  margin-right: 1rem;
  box-shadow: 1px 2px 7px 0px #084f41;
}

.item-card-header{
  width: 74px;
  height: 19px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000F1F;
}

.hustle-button-text-alt{
  color: #0A4F42 !important;
}

.hustle-button-alt {
  background: white !important;
  border: solid 1px #0A4F42;
}

.hustle-button-progress-status {
  background: #0A4F42 !important;
  padding: 5px 15px 5px 15px !important;
  height: auto !important;
}

.hustle-button-rejected-status {
  background: #880505 !important;
  padding: 5px 15px 5px 15px !important;
  height: auto !important;
}

.hustle-button-approval-status {
  background: #3a3fea !important;
  padding: 5px 15px 5px 15px !important;
  height: auto !important;
}

.hustle-button-applied-status {
  background: #FDBA40 !important;
  padding: 5px 15px 5px 15px !important;
  height: auto !important;
}

.hustle-button-booking-status {
  background: #000 !important;
  padding: 5px 15px 5px 15px !important;
  height: auto !important;
}

.login-button{
  background: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  border: solid 2px #084f41;
}

.h-screen-custom{
  height: 50vh;
}

.login-button-text{
  color: #084f41;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
}

.accordion-padding{
  padding-top: 1rem !important;
  padding-bottom: 0.2rem !important;
}

.add-project-button {
  display: flex;
  height: 45px;
  padding: 16px 14px 16px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #0A4F42;
}

.view-project-button {
  display: flex;
  height: 35px;
  padding: 16px 14px 16px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #0A4F42;
}

.category-card-button-text{
  color: #0A4F42;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  line-height: 140%; 
  letter-spacing: 0.2px;
}

.category-card-button-alt{
  display: flex;
  height: fit-content;
  width: fit-content;
  padding: 0px 15px 0px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: #fff;
  border: solid 2px #0A4F42;
  margin-bottom: 5px;
}

.category-card-button{
  display: flex;
  height: fit-content;
  width: fit-content;
  padding: 5px 15px 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: #fff;
  border: solid 2px #0A4F42;
  margin-bottom: 5px;
}

.hustle-button-reject{
  display: flex;
  height: 45px;
  padding: 16px 14px 16px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  border: solid 2px #CD0000;
}

.hustle-button{
  display: flex;
  height: 45px;
  padding: 16px 14px 16px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: #0A4F42;
}

.hustle-button-in-active{
  background: #3C4855 !important;
}

.review-text{
  color: #3C4855;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
}

.section-grid{
  justify-content: center;
  align-items: center;
  display: grid;
  margin: 1rem;
}

.card-exp-description-alt{
  text-align: right;
}

.card-exp-description{
  font-size: 14px !important;
}

.icon-white{
  color: white;
}

.search-button-alt{
  background: #000;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem;
  bottom: 0.425rem;
  right: 2.925rem;
  position: absolute;
}

.search-button{
  background: #000;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem;
  bottom: 0.425rem;
  position: absolute;
  inset-inline-end: 0.625rem;
}

.icon-width{
  width: 1rem;
}

.hustle-downloadable-title{
  color: #3C4855;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
}

.hustle-downloadable-container{
  border-radius: 4px;
  border: 1px solid #669A91;
  padding: 1rem;
}

.mobile-accordion-color{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #696969;
}

.accordion-color{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #696969;
}

.accordion-color:focus{
  color: #0A4F42 !important;
}

.accordion-paragraph{
  font-size: 16px;
}

.mobile-accordion-color:focus{
  color: #0A4F42 !important;
}

.search-button-text{
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
}

.search-input-box{
  background: white;
  border: solid 2px black;
  border-radius: 15px;
  font-size: 18px;
}

.search-input-box:focus{
  outline: none;
}

.tooltip-text-color-alt{
  color: white !important;
}

.tooltip-text-alt{
  width: auto !important;
}

.view-transaction-button {
  background: #679a91;
  padding: 0.2rem;
  width: 3rem;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px #000000ab;
}

.review-height{
  height: 4.8rem;
  overflow: auto;
}

.tooltip-text-alt-1 {
  width: 16rem !important;
}

.tooltip-text{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #434343;
  width: 4.7rem;
}

.card-description{
  color: #000F1F;
  font-family: "SF Pro Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.2px;
}

.learn-display:focus{
  outline: none;
}

.fav-border-alt{
  height: 2.5rem !important;
  width: 2.5rem !important;
  padding: 0.2rem !important;
}

.fav-border{
  width: 2rem;
  display: flex;
  height: 2rem;
  stroke-width: 1px;
  border-radius: 50%;
  border: solid 1px #FDBA40;
  justify-content: center;
  align-items: center;
}

.card-category-alt-text{
  color: #0A4F42;
  font-family: "SF Pro Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}

.card-category-alt{
  display: flex;
  padding: 5px 11px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  background-color: #C2D5D4;
  padding: 10px 11px !important;
}

.card-category{
  display: flex;
  padding: 5px 11px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  border: 1px solid #575757;
}

.hustle-category{
  padding: 10px 11px !important;
  border: 2px solid #575757 !important
}

.hustle-category-heading{
  font-size: 18px !important
}

.card-category-heading{
  color: #000F1F;
  font-family: "SF Pro Display";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.toggle-active-color{
  background-color: #0A4F42;
}

.toggle-inactive-color{
  color: #808080;
}

.modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main-alt{
  padding: 1rem !important;
  width: 70% !important;
}

.modal-main-alt-c{
  height: 30rem !important;
  overflow: hidden;
  overflow-y: scroll;
  width: 60% !important;
}

.modal-main {
  border-radius: 5px;
  padding: 2rem;
  position: fixed;
  background: white;
  width: 40%;
  height: auto;
  top: 45%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.modal-header-alt{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-header{
  display: flex;
  align-items: end;
  justify-content: end;
}

.modal-info-text{
  font-size: 1rem !important;
  font-weight: 300;
}

.modal-header-text{
  font-size: 1.5rem;
}

.modal-info{
  display: grid;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
}

.modal-header-icon-alt{
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.modal-header-icon{
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.modal-info-text-bold{
  font-weight: 600 !important;
}

@media screen and (max-width: 768px) {
  .bold-text{
    font-size: 22px;
  }

  .card-height{
    height: auto;
  }

  .virtual-card-bg{
    width: 100%;
  }

  .modal-main{
    width: 90%;
  }
}

.login-card {
  border: solid 1px black;
  border-radius: 5px;
  box-shadow: 1px 2px 7px 0px #afafd46b;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-no-outline:focus {
  outline: none;
}

.select-no-outline:active {
  outline: none;
}

:root {
  --clr-shade: #242526;
  --clr-light: #f6f6f6;
  --clr-alert: #ca3641;
}


#main {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  width: fit-content;
}

.p-card {
  border: 1px solid hsl(0, 0%, 0%, 0.25);
  box-shadow: 0px 0px 5px 0px #d7d7d7;
  height: 400px;
 	overflow: hidden;
  position: relative;
  width: 300px;
}

.p-card a {
  display: block;
	height: 100%;
  width: 100%;
}

.p-card img {
  display: block;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  transition: top 255ms ease-in-out;
  width: 101%;
  z-index: 0;
}

.p-card:hover img {
  top: -2rem;
}

.p-card a .caption {
  background-color: hsl(0, 0%, 0%, 0);
	display: grid;
  overflow: hidden;
  overflow-y: scroll;
  padding: 1rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 300px;
	transition-property: all;
  transition-duration: 0.2s;  
  width: 100%;  
  z-index: 10;  
}

.p-card a:hover .caption {
  background-color: hsl(0, 0%, 0%, 0.5);
	top: 0;
  transition: all 2s;
}

.p-card a .caption h2 {
  color: var(--clr-light);
  font-size: 1.8rem;
  text-shadow: 2px 2px 0 var(--clr-shade), -2px -2px 0 var(--clr-shade), -2px 2px 0 var(--clr-shade), 2px -2px 0 var(--clr-shade);
}

.hustle-button-a-display {
  display: none;
  transition: all 0.2s;
  width: 90%;
}

.p-card a .caption p {
  color: var(--clr-light);
  display: none;
  font-size: smaller;
  transition: all 0.2s;
  width: 90%;
}

.p-card a:hover .caption p {
  display: block;
}

.caption:hover .hustle-button-a-display {
  display: block;
}

.MuiInput-input:focus {
  border-bottom: solid 1px black;
}

.MuiInput-input:active{
  border-bottom: solid 1px black;
}

.MuiSvgIcon-root{
  color: #fff !important;
}

.MuiChip-root{
  height: 2rem !important;
  border-radius: 5px !important;
  color: white !important;
  background: #084f41 !important;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #084f41;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.back-project-design{
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  height: 4rem;
  background: linear-gradient(180deg, transparent 0, #4b4b4b78 10%, #fffefe);
  width: 100%;
}

.portfolio-container{
  height: 200px;
  width: 500px;
  position: relative;
  z-index: 20;
}

.portfolio-container:after {
  content: "";
  position: absolute;
  z-index: 3;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, transparent 0, transparent 10%, rgb(165, 165, 165));
  opacity: 0.8;
}

.portfolio-container-text {
  position: absolute;
  width: 94%;
  display: flex;
  justify-content: space-between;
  z-index: 20;
  bottom: 15px;
  left: 15px;
  color: white;
  font-size: 2rem;
}

/* @media (prefers-color-scheme: dark) {
  body{
    background: black;
  }
} */
